import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  ListItemText,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  BottomNavigation,
  BottomNavigationAction
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import {
  ArrowBack,
  Dashboard,
  Event,
  Favorite,
  Games,
  LocationOn,
  Logout,
  Restore
} from '@mui/icons-material';
import { useAuth } from '../../../contexts/authContext';
import { signOutUser } from '../../../services/auth';
import { useAlert } from '../../../contexts/alertContext';
import { useColorMode } from '../../../hooks/colorModeProvider';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailIcon from '@mui/icons-material/Mail';

import VenueSwitcher from '../../venues/switcher';
import BSBottomNavigation from './bottomNavigation';

import { useTitle } from '../../../hooks/titleProvider';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const Navigation = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const { authenticated, currentUserProfile } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { mode, toggleColorMode } = useColorMode();

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const { title } = useTitle();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const [navItems, setNavItems] = useState([
    {
      text: 'Dashboard',
      secondaryText: '',
      link: '/dashboard',
      icon: <Dashboard color="lightIcon" />
    },
    {
      text: 'New Private Game',
      secondaryText: '',
      link: '/new',
      icon: <Games color="lightIcon" />
    },
    {
      text: 'Events',
      secondaryText: '',
      link: '/events',
      icon: <Event color="lightIcon" />
    }
  ]);

  const handleSignOut = async () => {
    await signOutUser()
      .then(() => {
        showAlertSuccess('Successfully signed out!');

        //navigate("/signin");
      })
      .catch((err) => {
        showAlertError('Error signing out. Please try again.');
      });
  };

  const drawerItems = (
    <List>
      {navItems.map((item, index) => (
        <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
          <ListItemButton
            onClick={() => navigate(item.link)}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position={'sticky'} open={open}>
        <Toolbar>
          {window.location.pathname !== '/dashboard' && (
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              sx={{
                marginRight: 5,
                color: 'text.primary'
              }}>
              <ArrowBack />
            </IconButton>
          )}

          <Typography sx={{ flexGrow: 1 }} width={'100%'}>
            {title}
          </Typography>

          <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
            <VenueSwitcher />
            {authenticated && (
              <>
                <IconButton onClick={() => handleSignOut()} color="inherit">
                  <Logout />
                </IconButton>
              </>
            )}

            <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Typography variant="h6" component="div">
            Bowlscout
          </Typography>
          <IconButton onClick={handleDrawerClose} sx={{ color: 'text.primary' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box display="flex" flexDirection={'column'} height={'100%'} alignItems={'flex-start'}>
          {drawerItems} */}

      {/* </Box>
      </Drawer> */}
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mb={'55px'}>
        <Box component="main" sx={{ flexGrow: 1, p: 2 }} width={'100%'}>
          {/* <DrawerHeader /> */}
          {children}
        </Box>
      </Box>
      <BSBottomNavigation />
    </>
  );
};

export default Navigation;
