import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useDropzone } from 'react-dropzone';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';

import ImportExportIcon from '@mui/icons-material/ImportExport';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadFile } from '../../helpers/storageHelper';
import { uploadPlayers } from '../../features/player/playerSlice';
// import * as yup from 'yup';
// import { yupREsolver } from '@hookform/resolvers';
// import { useForm } from 'react-hook-form';

// const schema = yup.object().shape({
//   uploadData: yup.object()
// });

const ImportPlayerDialog = () => {
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event.event);

  const [open, setOpen] = useState(false);
  const [droppedFile, setDroppedFile] = useState();

  useEffect(() => {
    if (droppedFile) {
      importFile(droppedFile);
      console.log(droppedFile);
    }
  }, [droppedFile]);

  const onDrop = useCallback(async (droppedFile) => {
    setDroppedFile(droppedFile[0]);
    // const formData = new FormData();
    // formData.append('file', droppedFile[0]);

    // props.onUpload(formData);
  }, []);

  const importFile = async (fileData) => {
    const { fileName } = await uploadFile(fileData);

    if (fileName) {
      dispatch(uploadPlayers({ fileName, eventId: event.id }));
    }

    console.log(fileName);
  };

  const handleRemove = (file) => {
    setDroppedFile(null);
    // resetField(name, { keepDirty: true, keepError: true });
  };

  const handleClose = () => {
    setDroppedFile(null);
    setOpen(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'xlsx'
  });

  return (
    <>
      <Tooltip title="Import Players">
        <IconButton color="lightIcon" onClick={() => setOpen(true)}>
          <ImportExportIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Import Players</DialogTitle>
        <DialogContent>
          <Box
            {...getRootProps()}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            {droppedFile && !droppedFile.path ? (
              <Fragment>
                <Typography>Loading...</Typography>
              </Fragment>
            ) : droppedFile ? (
              <>
                <Typography>Uploaded File</Typography>
                <Typography>{droppedFile.name} </Typography>
              </>
            ) : (
              <>
                <input {...getInputProps({ multiple: false })} />
                <CloudUploadIcon fontSize="large" />
                {isDragActive ? (
                  <Typography>Drop the files here ...</Typography>
                ) : (
                  <Typography>Drag 'n' drop files here, or click to select files</Typography>
                )}
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImportPlayerDialog;
