import React from 'react';
import { Box, Stack } from '@mui/system';
import Navigation from '../components/ui/navigation';
import { useAuth } from '../contexts/authContext';

const PrivateLayout = ({ children, ...props }) => {
  return <Navigation>{children}</Navigation>;
};

export default PrivateLayout;
