import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import playerService from './playerService';

export const uploadPlayers = createAsyncThunk('player/uploadPlayers', async (data, thunkAPI) => {
  return await playerService
    .uploadPlayers(data.fileName, data.eventId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const playerSlice = createSlice({
  name: 'players',
  initialState: {
    player: null,
    players: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadPlayers.fulfilled, (state, action) => {
        state.uploadingPlayers = false;
        state.players = action.payload;
      })
      .addCase(uploadPlayers.rejected, (state, action) => {
        state.uploadingPlayers = false;
        state.players = [];
      })
      .addCase(uploadPlayers.pending, (state, action) => {
        state.uploadingPlayers = true;
        state.players = [];
      });
  }
});

export default playerSlice.reducer;
