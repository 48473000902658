import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Paper, Typography } from '@mui/material';
import { scoreGame } from '../../../features/game/gameSlice';
import { GameStatus } from '../../../constants/gameConstants';

const ScoreControl = ({ game, team }) => {
  const dispatch = useDispatch();
  const scoringGame = useSelector((state) => state.game.scoringGame);
  const currentSet = game.sets.find((set) => set.id === game.currentSet);

  if (
    game.status === GameStatus.COMPLETE ||
    game.status === GameStatus.PLAYOFF ||
    currentSet.isDone
  ) {
    return null;
  }

  const handleScoreUpdate = (scoreValue) => {
    dispatch(scoreGame({ gameId: game.id, teamId: team.id, scoreValue }));
  };

  const scoreButton = (value) => {
    return (
      <Box
        m={'5px'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Button
          variant={'contained'}
          color={'background'}
          disabled={scoringGame}
          sx={{ width: 40, height: 40, borderRadius: 3, fontWeight: 'bold' }}
          onClick={() => handleScoreUpdate(value)}>
          {value}
        </Button>
      </Box>
    );
  };

  const scoreZeroButton = () => {
    return (
      <Box
        m={'5px'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Button
          variant={'contained'}
          color={'background'}
          elevation={2}
          disabled={scoringGame}
          sx={{ width: '100%', height: 40, borderRadius: 3, fontWeight: 'bold' }}>
          {0}
        </Button>
      </Box>
    );
  };

  return (
    <Paper sx={{ mt: 3, width: '160px', alignSelf: 'center', borderRadius: 5, p: '5px' }}>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
        {scoreButton(1)}
        {scoreButton(2)}
        {scoreButton(3)}
        {scoreButton(4)}
      </Box>
      <Box>{scoreZeroButton()}</Box>
    </Paper>
  );
};

export default ScoreControl;
