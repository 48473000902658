import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  TableBody,
  Button
} from '@mui/material';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { setLoadingEvent, setEvent } from '../../features/event/eventSlice';

import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { useColorMode } from '../../hooks/colorModeProvider';
import PoweredBy from '../ui/poweredBy';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: 'none',
    fontSize: 12
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

const EmbeddedSectionsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector((state) => state.event.event);
  const loading = useSelector((state) => state.event.loading);

  const { mode, setMode } = useColorMode();

  useEffect(() => {
    setMode('light');
  }, [mode, setMode]);

  //Get Event ID from params.
  const { eventId } = useParams();

  //Get event by eventId
  useEffect(() => {
    if (!eventId) return;

    dispatch(setLoadingEvent(true));
    const docRef = doc(store, 'events', eventId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        dispatch(setEvent(eventData));
      } else {
        console.log('No such document!');
      }
      dispatch(setLoadingEvent(false));
    });

    return () => unsubscribe();
  }, [eventId, dispatch]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <>
          <Typography variant="h4" textAlign="center" mt={2}>
            Sections
          </Typography>

          <Stack>
            {event.sections.map((section) => (
              <TableContainer
                key={section.id}
                component={Paper}
                sx={{
                  m: 2,
                  borderRadius: 3,
                  maxWidth: 400,
                  border: '1px solid',
                  borderColor: 'primary.main'
                }}>
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell padding="normal" sx={{ fontWeight: 'bold' }} colSpan={2}>
                        {section.name}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell padding="normal" align="right">
                        <Typography>
                          Total Games: {section.games ? section.games.length : 0}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell padding="normal" align="right">
                        <Button
                          variant="contained"
                          color="info"
                          onClick={() => navigate(`/embed/games/${section.id}`)}>
                          View Games
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </Stack>

          <PoweredBy />
        </>
      )}
    </Box>
  );
};

export default EmbeddedSectionsView;
