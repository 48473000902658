import axiosInstance from '../axiosInstance';
import { store } from '../../config/firebase';

import { collection, getDocs, addDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';

//CRUD Functions
// const getAll = async () => {
//   const collectionRef = collection(store, 'venues');
//   const snapshot = await getDocs(collectionRef);
//   const venues = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//   return venues;
// };

const getAll = async () => {
  return axiosInstance.get('/venue').then((response) => {
    return response.data;
  });
};

const getByUserId = async (userId) => {
  return axiosInstance.get(`/venue/user/${userId}`).then((response) => {
    return response.data;
  });
};

const getById = async (id) => {
  return axiosInstance.get(`/venue/${id}`).then((response) => {
    return response.data;
  });
};

const save = async (venue) => {
  const collectionRef = collection(store, 'venues');

  const docRef = await addDoc(collectionRef, venue);
  return docRef.id;
};

const update = async (id, venue) => {
  const docRef = doc(store, 'venues', id);
  await setDoc(docRef, venue);
};

const remove = async (id) => {
  const docRef = doc(store, 'venues', id);
  await deleteDoc(docRef);
};

const venueService = { getById, getAll, getByUserId, save, update, remove };

export default venueService;
