import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

const StyledSectionTotalTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
    borderBottom: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

const SectionTotalsCard = ({ section }) => {
  if (section.sectionTotals && section.sectionTotals.teams.length !== 0) {
    let teamSetTotals = section.sectionTotals.teams;
    const mutableTeamSetTotals = Array.from(teamSetTotals);

    mutableTeamSetTotals.sort((a, b) => {
      // Sort by totalPoints (descending)
      if (b.totalPoints !== a.totalPoints) {
        return b.totalPoints - a.totalPoints;
      }
      // If totalPoints are equal, sort by net (descending)
      return b.totalNet - a.totalNet;
    });

    teamSetTotals = mutableTeamSetTotals;

    return (
      <TableContainer
        key={section.id}
        component={Paper}
        sx={{
          borderRadius: 3,
          maxWidth: 400,
          border: '1px solid',
          borderColor: 'blue.main'
        }}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <StyledSectionTotalTableCell padding="normal">
                <Typography variant="body1" fontWeight={'bold'}>
                  {section.sectionName}
                </Typography>
              </StyledSectionTotalTableCell>
              {/* <StyledSectionTotalTableCell padding="normal" align={'center'}>
                Sets
              </StyledSectionTotalTableCell> */}
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                Total
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                Net
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamSetTotals.map((team) => (
              <TableRow key={team.teamId}>
                <StyledSectionTotalTableCell size="small" padding="normal">
                  {team.teamName}
                </StyledSectionTotalTableCell>
                {/* <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.numberOfSets}
                </StyledSectionTotalTableCell> */}
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalPoints}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalNet}
                </StyledSectionTotalTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer
        key={section.id}
        component={Paper}
        sx={{
          borderRadius: 3,
          maxWidth: 400,
          border: '1px solid',
          borderColor: 'blue.main'
        }}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <StyledSectionTotalTableCell padding="normal">
                <Typography variant="body1" fontWeight={'bold'}>
                  {section.sectionName}
                </Typography>
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledSectionTotalTableCell size="small" padding="normal">
                No data available yet, check back again later.
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default SectionTotalsCard;
