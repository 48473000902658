import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Paper, Typography } from '@mui/material';
import { scorePlayoffGame } from '../../../features/game/gameSlice';
import { GameStatus } from '../../../constants/gameConstants';
import AddIcon from '@mui/icons-material/Add';

const PlayoffScoreControl = ({ game, team }) => {
  const dispatch = useDispatch();
  const scoringGame = useSelector((state) => state.game.scoringGame);

  if (game.status === GameStatus.PLAYOFF && game.playoff.isDone) {
    return null;
  }

  const handleScoreUpdate = () => {
    dispatch(scorePlayoffGame({ gameId: game.id, teamId: team.id, scoreValue: 1 }));
  };

  const increaseScore = () => {
    return (
      <Box
        m={1}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <IconButton
          variant={'contained'}
          color={'background'}
          elevation={2}
          disabled={scoringGame}
          sx={{ width: 50, height: 50, borderRadius: 3, fontWeight: 'bold' }}
          onClick={() => handleScoreUpdate()}>
          <AddIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <Paper sx={{ mt: 3, alignSelf: 'center', borderRadius: 5, p: 1 }}>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
        {increaseScore()}
      </Box>
    </Paper>
  );
};

export default PlayoffScoreControl;
