import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { auth } from '../config/firebase';
import { store } from '../config/firebase';

export const getSignedInUser = async (userId) => {
  //Get User Profile from store
  const userRef = doc(store, 'users', userId);
  const userSnap = await getDoc(userRef);
  const user = userSnap.data();

  return user;
};

//Create user profile when signed up, the auth user will return a ID that needs to be used for the user id.
export const createUserProfile = async (userId, user) => {
  const userRef = doc(store, 'users', userId);
  //Include timestamps
  user.createdAt = new Date();
  user.updatedAt = new Date();

  await setDoc(userRef, user);
};

export const getUserByEmail = async (email) => {
  try {
    const usersCollection = collection(store, 'users');
    const q = query(usersCollection, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const user = userDoc.data();
      return user;
    } else {
      console.log('No user found with the provided email.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user by email: ', error);
    throw error;
  }
};
