import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Dashboard, Event, Games, IndeterminateCheckBox } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function BSBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const navItems = [
    {
      text: 'Overview',
      link: '/dashboard',
      icon: <Dashboard />
    },
    {
      text: 'Events',
      link: '/events',
      icon: <Event />
    }
  ];

  // Update the value state based on the current path
  React.useEffect(() => {
    const currentPath = location.pathname;
    const currentIndex = navItems.findIndex((item) => item.link === currentPath);
    if (currentIndex !== -1) {
      setValue(currentIndex);
    }
  }, [location.pathname, navItems]);

  return (
    <Box width={'100%'} position={'fixed'} bottom={0}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(navItems[newValue].link);
        }}>
        {navItems.map((item, index) => (
          <BottomNavigationAction key={index} label={item.text} icon={item.icon} />
        ))}
      </BottomNavigation>
    </Box>
  );
}
