import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';

const Input = forwardRef((props, ref) => {
  return (
    <TextField variant="outlined" margin="dense" inputRef={ref} fullWidth {...props} size="small" />
  );
});

// const Input = ({ name, label, control, helperText, ...props }) => {
//   <Controller
//     control={control}
//     name={name}
//     defaultValue={props.value || false}
//     render={({ field: { onChange, value } }) => (
//       <TextField
//         variant="outlined"
//         margin="dense"
//         fullWidth
//         onChange={onChange}
//         value={value}
//         size="small"
//         {...props}
//       />
//     )}
//   />;
// };

export default Input;
