import React from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Container, Divider, Paper, Typography } from '@mui/material';

import AuthTemplate from './authTemplate';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../ui/buttons/primaryButton';
import Input from '../ui/forms/fields/input';
import { useAlert } from '../../contexts/alertContext';

import { resetUserPassword } from '../../services/auth';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required')
});

const ForgotPassword = () => {
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const { showAlertSuccess, showAlertError } = useAlert();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onForgotPassword = async (data) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        await resetUserPassword(data.email);
        showAlertSuccess('Password reset link sent to your email!');
        navigate('/signin');
      } catch (err) {
        showAlertError('Please check your email and try again.');
      }

      setIsSigningIn(false);
    }
  };

  return (
    <AuthTemplate>
      <Typography variant="h5" gutterBottom>
        Forgot Password
      </Typography>
      <Typography variant="body2" gutterBottom>
        Enter your email address and we will send you a link to reset your password.
      </Typography>

      <Input
        label="Email"
        variant="outlined"
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <Box display="flex" justifyContent={'center'} alignItems={'center'} my={2}>
        <PrimaryButton
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(onForgotPassword)()}>
          Submit
        </PrimaryButton>
      </Box>
      <Divider sx={{ m: 2 }} />
      <PrimaryButton variant="outlined" color="info" onClick={() => navigate('/signin')}>
        Cancel
      </PrimaryButton>
    </AuthTemplate>
  );
};

export default ForgotPassword;
