import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';
import { act, useState } from 'react';
import ScoreDisplay from '../playerDisplay/scoreDisplay';
import { useNavigate } from 'react-router-dom';
import InputScoreDialog from '../../score/inputScoreDialog';

import { useAuth } from '../../../contexts/authContext';
import { Roles, hasAccess } from '../../../helpers/access';

const GameInProgressCard = ({ event, game, actions }) => {
  const navigate = useNavigate();
  const [scoreWayOpen, setScoreWayOpen] = useState(false);

  const { authenticated, currentUserProfile } = useAuth();

  const handleCloseScoreWay = () => {
    setScoreWayOpen(false);
  };

  return (
    <>
      <Stack justifyContent={'space-between'} height="100%">
        <ScoreDisplay game={game} />

        {actions && (
          <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} justifySelf={'flex-end'}>
            {hasAccess(currentUserProfile, Roles.SCORER) && (
              <PrimaryButton
                color="success"
                variant="contained"
                onClick={() => setScoreWayOpen(true)}>
                Score Game
              </PrimaryButton>
            )}
          </Box>
        )}
      </Stack>

      <Dialog open={scoreWayOpen} onClose={handleCloseScoreWay} fullWidth maxWidth="sm">
        <DialogTitle>Score Game</DialogTitle>
        <DialogContent>
          <DialogContentText> How would you like to score the game.</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button onClick={handleCloseScoreWay} color="primary">
            Cancel
          </Button>

          {hasAccess(currentUserProfile, Roles.SCORER) && (
            <>
              <InputScoreDialog game={game} onOpen={() => setScoreWayOpen(false)} />

              <Button
                variant="contained"
                onClick={() => navigate(`/game/scoring/${game.id}`)}
                color="primary">
                Score Live
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameInProgressCard;
