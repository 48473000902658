import { Box, Stack } from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';
import ScoreDisplay from '../playerDisplay/scoreDisplay';
import { useNavigate } from 'react-router-dom';

const GameCompleteCard = ({ event, game, actions }) => {
  const navigate = useNavigate();

  return (
    <Stack justifyContent={'space-between'} height="100%">
      <ScoreDisplay game={game} />

      {actions && (
        <Box display={'flex'} flexDirection={'row'} alignSelf={'center'} justifySelf={'flex-end'}>
          <PrimaryButton
            color="info"
            variant="contained"
            onClick={() => navigate(`/game/scoring/${game.id}`)}>
            View
          </PrimaryButton>
        </Box>
      )}
    </Stack>
  );
};

export default GameCompleteCard;
