const { Stack, Typography } = require('@mui/material');

const PlayerNames = ({ team }) => {
  return (
    <Stack
      sx={{
        border: '3px solid',
        borderColor: `${team.color}.main`,
        borderRadius: 5,
        p: 3
      }}>
      {team.players?.map((player, index) => (
        <Typography key={index} variant="caption">
          {player.length > 10 ? `${player.substring(0, 10)}...` : player}
        </Typography>
      ))}
    </Stack>
  );
};

export default PlayerNames;
