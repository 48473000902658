import gameFormatService, {
  GameType,
} from "../features/gameFormat/gameFormatService";

const generateTeamName = (gameType, players) => {
  //Generate team names based on game type etc.
  let teamName = "";

  switch (gameType) {
    case GameType.SINGLES:
      teamName = `${players[0].name} ${players[0].surname}`;
      return teamName;
    case GameType.PAIRS: {
      const playerNames = [];
      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        //const firstLetterName = player.name.substring(0, 1);

        playerNames.push(`${player.name} ${player.surname}`);
      }
      teamName = playerNames.join(", ");
      return teamName;
    }
    case GameType.TRIPLES: {
      const playerNames = [];
      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        const firstLetterName = player.name.substring(0, 1);

        playerNames.push(`${firstLetterName}. ${player.surname}`);
      }
      teamName = playerNames.join(", ");
      return teamName;
    }
    case GameType.QUADS: {
      const playerNames = [];
      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        const firstLetterName = player.name.substring(0, 1);

        playerNames.push(`${firstLetterName}. ${player.surname}`);
      }
      teamName = playerNames.join(", ");
      return teamName;
    }
    default:
      return teamName;
  }
};

export { generateTeamName };
