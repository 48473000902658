import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { undoLastScore, undoPlayoffScore } from '../../../features/game/gameSlice';
import { GameStatus } from '../../../constants/gameConstants';
import { useAuth } from '../../../contexts/authContext';
import { hasAccess } from '../../../helpers/access';

const UndoButton = ({ game }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const undoing = useSelector((state) => state.game.undoing);

  const { currentUserProfile } = useAuth();
  const role = currentUserProfile.role;

  const handleUndo = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmUndo = () => {
    if (game.status === GameStatus.PLAYOFF && game.playoff) {
      dispatch(undoPlayoffScore(game.id))
        .unwrap()
        .then(() => {
          setOpen(false);
        });
    } else {
      dispatch(undoLastScore(game.id))
        .unwrap()
        .then(() => {
          setOpen(false);
        });
    }
  };

  return (
    hasAccess(currentUserProfile, 'scorer') && (
      <>
        <Button
          variant={'text'}
          color={game.status === GameStatus.PLAYOFF && game.playoff ? 'warning' : 'primary'}
          elevation={2}
          sx={{ width: '100%', mt: 1, fontWeight: 'bold' }}
          onClick={() => handleUndo()}>
          Undo
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Undo Last Score</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to undo the last score? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {undoing ? (
              <CircularProgress />
            ) : (
              <>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleConfirmUndo} color={'primary'} autoFocus>
                  Undo
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default UndoButton;
