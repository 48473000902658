import { Stack, Typography } from '@mui/material';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { store } from '../../config/firebase';
import BSCircularProgress from '../ui/progress/circularProgress';

const FeaturedEvent = () => {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(false);

  useEffect(() => {
    setLoading(true);
    const collectionRef = collection(store, 'events');
    const q = query(collectionRef, where('featured', '==', true)); // Replace 'your-start-date' with the actual date you want to filter by

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const eventsData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      setEvent(eventsData[0]); // Assuming setEvents action is used to store multiple events
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Stack>
      <Typography variant="h4">Featured</Typography>
      {loading ? (
        <BSCircularProgress caption="Loading Featured Event" />
      ) : (
        event && <Typography> {event.name} </Typography>
      )}
    </Stack>
  );
};

export default FeaturedEvent;
