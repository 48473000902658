import React, { useEffect, useState } from 'react';

const TitleContext = React.createContext();
export const useTitle = () => React.useContext(TitleContext);

const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState('Home');

  useEffect(() => {
    document.title = `${title} | Bowlscout`;
  }, [title]);

  return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>;
};

export default TitleProvider;
