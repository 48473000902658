import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import PrimaryButton from '../ui/buttons/primaryButton';
import { updateGame } from '../../features/game/gameSlice';
import Input from '../ui/forms/fields/input';

const schema = yup.object().shape({
  name: yup.string().required('Please enter game name')
});

const EditGame = ({ game, open, onClose }) => {
  const dispatch = useDispatch();
  const eventSections = useSelector((state) => state.event?.event?.sections);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (game.name === undefined || game.name === 'undefined') {
      try {
        const gameSection = eventSections.find((section) => section.id === game.section);
        const sectionGameIndex = gameSection.games.findIndex(
          (sectionGame) =>
            game.teams.teamA.id === sectionGame.teamA && sectionGame.teamB === game.teams.teamB.id
        );

        if (sectionGameIndex > -1) {
          const gameName = gameSection.name + ' Game ' + (sectionGameIndex + 1);
          setValue('name', gameName);
        } else {
          setValue('name', '');
        }
      } catch (error) {
        setValue('name', '');
      }
    } else {
      setValue('name', game.name);
    }

    return () => {
      reset();
    };
  }, [reset, game.name, eventSections, game.section]);

  const saveGame = (data) => {
    dispatch(updateGame({ ...data, id: game.id }));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Game</DialogTitle>
      <DialogContent>
        <Input label="Game Name" {...register('name')} />
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={() => handleSubmit(saveGame)()}>Save</PrimaryButton>
        <PrimaryButton onClick={() => onClose()}>Cancel</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditGame;
