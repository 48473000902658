import { auth } from "../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
} from "firebase/auth";

export const registerUser = async (email, password) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInUser = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const signInUserWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  return await signInWithPopup(auth, provider);
};

export const signOutUser = async () => {
  return await auth.signOut();
};

//PasswordReset
export const resetUserPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};

//PasswordUpdate
export const updateUserPassword = async (password) => {
  return await updatePassword(auth.currentUser, password);
};

//Send Email Verification
export const sendUserEmailVerification = async () => {
  return await sendEmailVerification(auth.currentUser);
};
