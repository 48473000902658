import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gameFormatService from './gameFormatService';

export const getAllGameFormats = createAsyncThunk(
  'gameFormats/getAllGameFormats',
  async (data, thunkAPI) => {
    return await gameFormatService
      .getAll()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const gameFormatSlice = createSlice({
  name: 'gameFormats',
  initialState: {
    gameFormat: null,
    gameFormats: []
  },
  reducers: {
    setGameFormat: (state, action) => {
      state.gameFormat = action.payload;
    },
    setGameFormats: (state, action) => {
      state.gameFormats = action.payload;
    },
    addGameFormat: (state, action) => {
      state.gameFormats.push(action.payload);
    },
    updateGameFormat: (state, action) => {
      const index = state.gameFormats.findIndex(
        (gameFormat) => gameFormat.id === action.payload.id
      );
      state.gameFormats[index] = action.payload;
    },
    removeGameFormat: (state, action) => {
      state.gameFormats = state.gameFormats.filter(
        (gameFormat) => gameFormat.id !== action.payload
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllGameFormats.fulfilled, (state, action) => {
        state.gameFormats = action.payload;
        state.loading = false;
      })
      .addCase(getAllGameFormats.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllGameFormats.pending, (state, action) => {
        state.loading = true;
      });
  }
});

export const { setGameFormat, setGameFormats, addGameFormat, updateGameFormat, removeGameFormat } =
  gameFormatSlice.actions;

export default gameFormatSlice.reducer;
