import React, { Fragment, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  MenuItem,
  CircularProgress,
  IconButton,
  useTheme
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import PrimaryButton from '../ui/buttons/primaryButton';
import eventService from '../../features/event/eventService';

import AddIcon from '@mui/icons-material/Add';
import { trusted } from 'mongoose';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  surname: yup.string().required('Surname is required'),
  email: yup.string().email('Invalid email'),
  handicap: yup.number().typeError('Handicap must be a number')
});

const PlayerViewDialog = ({ player, event, open, setOpen }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: player
  });

  const [saving, setSaving] = React.useState(false);

  useEffect(() => {
    if (player) {
      setValue('name', player.name);
      setValue('surname', player.surname);
      setValue('email', player.email);
      setValue('handicap', player.handicap);
    }

    return () => {
      setSaving(false);
      resetForm();
    };
  }, [setSaving, player, setValue]);

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    reset(
      {
        name: '',
        surname: '',
        email: '',
        handicap: ''
      },
      { keepValues: false, keepDirty: false }
    );
  };

  const onSubmit = async (data) => {
    setSaving(true);

    if (player) {
      data.id = player.id;
      await eventService.updatePlayer(event.id, data);
    } else {
      await eventService.addPlayer(event.id, data);
    }

    setSaving(false);
    resetForm();
    handleClose();
  };

  const onAddCreate = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h6" color="primary">
            Player Details
          </Typography>
        </DialogTitle>
        <Box sx={{ p: 2 }}>
          <Box display={'flex'} gap={2}>
            <Input label="Name*" {...register('name')} error={errors.name} focused={true} />
            <Input label="Surname*" {...register('surname')} error={errors.surname} />
            <Input
              label="Handicap"
              {...register('handicap')}
              error={errors.handicap}
              type="number"
              sx={{ maxWidth: 150 }}
            />
          </Box>

          <Input
            label="Email (Optional)"
            {...register('email')}
            error={errors.email}
            fullWidth
            helperText={'Add email to link user for progress tracking.'}
          />
        </Box>
        <DialogActions>
          {saving ? (
            <CircularProgress />
          ) : (
            <Fragment>
              <PrimaryButton onClick={() => handleClose()}>Cancel</PrimaryButton>
              <PrimaryButton onClick={() => onAddCreate()} variant={'contained'}>
                Save
              </PrimaryButton>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PlayerViewDialog;
