import axiosInstance from '../axiosInstance';

const get = async () => {
  return axiosInstance.get('/game').then((response) => {
    return response.data;
  });
};

const getById = async (gameId) => {
  return axiosInstance.get(`/game/${gameId}`).then((response) => {
    return response.data;
  });
};

const getByEventId = async (eventId) => {
  return axiosInstance.get(`/game/event/${eventId}`).then((response) => {
    return response.data;
  });
};

const getBySectionId = async (sectionId) => {
  return axiosInstance.get(`/game/section/${sectionId}`).then((response) => {
    return response.data;
  });
};

const getByUserId = async () => {
  return axiosInstance.get('/game/user').then((response) => {
    return response.data;
  });
};

const create = async (game) => {
  return axiosInstance.post('/game', game).then((response) => {
    return response.data;
  });
};

const update = async (gameId, game) => {
  return axiosInstance.put(`/game/${gameId}`, game).then((response) => {
    return response.data;
  });
};

const remove = async (gameId) => {
  return axiosInstance.delete(`/game/${gameId}`).then((response) => {
    return response.data;
  });
};

const startGame = async (gameId) => {
  return axiosInstance.put(`/game/start/${gameId}`).then((response) => {
    return response.data;
  });
};

const scoreGame = async (data) => {
  return axiosInstance.put(`/game/score/${data.gameId}`, data).then((response) => {
    return response.data;
  });
};

const undoLastScore = async (gameId) => {
  return axiosInstance.put(`/game/undo/${gameId}`).then((response) => {
    return response.data;
  });
};

const startNewSet = async (gameId) => {
  return axiosInstance.put(`/game/newSet/${gameId}`).then((response) => {
    return response.data;
  });
};

const startPlayoff = async (gameId) => {
  return axiosInstance.put(`/game/playoff/start/${gameId}`).then((response) => {
    return response.data;
  });
};

const scorePlayoffGame = async (data) => {
  return axiosInstance.put(`/game/playoff/score/${data.gameId}`, data).then((response) => {
    return response.data;
  });
};

const undoPlayoffScore = async (gameId) => {
  return axiosInstance.put(`/game/playoff/undo/${gameId}`).then((response) => {
    return response.data;
  });
};

const inputSetScore = async (data) => {
  return axiosInstance.put(`/game/inputSetScore/${data.gameId}`, data).then((response) => {
    return response.data;
  });
};

const gameService = {
  get,
  getById,
  getByEventId,
  getBySectionId,
  getByUserId,
  create,
  update,
  remove,
  startGame,
  scoreGame,
  undoLastScore,
  startNewSet,
  startPlayoff,
  scorePlayoffGame,
  undoPlayoffScore,
  inputSetScore
};

export default gameService;
