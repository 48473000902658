import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, MenuItem } from '@mui/material';
import Select from '../forms/fields/select';
import { useFormContext } from 'react-hook-form';

const GreenSelector = ({ game }) => {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();

  const venue = useSelector((state) => state.venue.selectedVenue);

  const [green, setGreen] = useState(
    game ? venue.greens.find((g) => g.name === game.green) : undefined
  );

  const watchGreen = watch('green');

  useEffect(() => {
    if (venue?.greens) {
      setGreen(venue.greens.find((g) => g.name === watchGreen));
    }
  }, [watchGreen]);

  return (
    <Box display={'flex'} gap={2}>
      <Select
        control={control}
        name={'green'}
        defaultValue={game?.green}
        label="Green"
        error={errors?.green}
        helperText={errors?.green?.message}>
        {venue &&
          venue?.greens.map((green) => (
            <MenuItem key={green.name} value={green.name}>
              {green.name}
            </MenuItem>
          ))}
      </Select>
      {green && (
        <Select
          control={control}
          name={'rink'}
          defaultValue={game?.rink}
          label="Rink"
          error={errors?.rink}
          helperText={errors?.rink?.message}>
          {(() => {
            let container = [];
            for (let i = 0; i < green.rink_count; i++) {
              container.push(
                <MenuItem key={i} value={i + 1}>
                  {i + 1}
                </MenuItem>
              );
            }
            return container;
          })()}
        </Select>
      )}
    </Box>
  );
};

export default GreenSelector;
