import React from 'react';
import { Box, Paper } from '@mui/material';
import Logo from '../../resources/assets/images/logo192.png';
import PoweredBy from '../ui/poweredBy';

const AuthTemplate = ({ children }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      height={'100vh'}>
      <Paper>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={5}>
          <img src={Logo} alt="logo" width={192} />
          {children}
          <PoweredBy />
        </Box>
      </Paper>
    </Box>
  );
};

export default AuthTemplate;
