import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  TableBody,
  Button,
  IconButton
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { setLoadingEvent, setEvent } from '../../features/event/eventSlice';

import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { useColorMode } from '../../hooks/colorModeProvider';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PoweredBy from '../ui/poweredBy';

const EmbeddedSectionsGames = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);

  const { mode, setMode } = useColorMode();

  useEffect(() => {
    setMode('light');
  }, [mode, setMode]);

  //Get Event ID from params.
  const { eventId, sectionId } = useParams();

  //Get event by eventId
  useEffect(() => {
    if (!sectionId) return;
    setLoading(true);

    const gamesRef = collection(store, 'games');
    const q = query(gamesRef, where('section', '==', sectionId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const games = [];
      querySnapshot.forEach((doc) => {
        games.push({ id: doc.id, ...doc.data() });
      });

      setGames(games);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [sectionId, dispatch]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m={2}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <IconButton
          onClick={() => navigate(-1)}
          sx={{ position: 'absolute', left: 10 }}
          color="info">
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h4">Section Games</Typography>
      </Box>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Stack sx={{ m: 2 }} gap={3}>
          {games && games.length > 0 ? (
            games.map((game, index) => (
              <GameByStatusCards game={game} key={index} actions={false} />
            ))
          ) : (
            <Typography>No Games</Typography>
          )}
        </Stack>
      )}

      <PoweredBy />
    </Box>
  );
};

export default EmbeddedSectionsGames;
