import { storage } from '../config/firebase';
import { getDownloadURL, ref, uploadBytes, getMetadata } from 'firebase/storage';

export const uploadFile = async (file, path = 'uploads/') => {
  try {
    const storageRef = ref(storage, `${path}${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    const fileMeta = await getMetadata(snapshot.ref);
    return { downloadURL, fileName: fileMeta.name }; // This URL can be used to view or download the file
  } catch (error) {
    console.error('Upload failed', error);
    throw error; // Re-throw to handle in your UI
  }
};

export const downloadFile = async (path) => {
  try {
    const storageRef = ref(storage, path);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL; // Use this URL to download or display the file
  } catch (error) {
    console.error('Download failed', error);
    throw error;
  }
};
