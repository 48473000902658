import React, { useEffect, useState } from 'react';
import { Typography, Box, Button } from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import { useNavigate } from 'react-router-dom';
import Logo from '../../resources/assets/images/logo192.png';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../config/firebase';
import BSCircularProgress from '../ui/progress/circularProgress';
import EventCard from '../ui/cards/eventCard';
import FeaturedEvent from '../events/featuredEvent';

const Home = () => {
  const navigate = useNavigate();
  const [loadingEvents, setLoadingEvents] = useState();
  const [events, setEvents] = useState();

  const dateFilter = '2024-11-15';

  useEffect(() => {
    setLoadingEvents(true);
    const collectionRef = collection(store, 'events');
    const q = query(collectionRef, where('startDate', '>=', dateFilter)); // Replace 'your-start-date' with the actual date you want to filter by

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const eventsData = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data()
      }));
      setEvents(eventsData); // Assuming setEvents action is used to store multiple events
      setLoadingEvents(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} p={1}>
      <Button
        variant="contained"
        color="primary"
        sx={{ m: 2, alignSelf: 'flex-end' }}
        onClick={() => navigate('/signin')}>
        Sign In
      </Button>

      <Box display={'flex'} flexDirection={'row'} p={1} width={'100%'} gap={3}>
        {/* <img src={Logo} alt="logo" width={192} /> */}
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant="h4">Events</Typography>
          {loadingEvents ? (
            <BSCircularProgress />
          ) : events && events.length > 0 ? (
            events.map((event) => <EventCard event={event} />)
          ) : (
            <Typography>No Data Available</Typography>
          )}
        </Box>
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'}>
          <FeaturedEvent />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
