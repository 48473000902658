import { Box, Stack, Typography } from '@mui/material';
import PlayerNames from './playerNames';

const MultiplePlayerDisplay = ({ teams }) => {
  if (!teams) return null;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={'space-evenly'}
      alignItems={'center'}
      gap={1}>
      <PlayerNames team={teams.teamA} />
      <Typography variant="h6" color="primary" fontWeight="bold" sx={{ mx: 2 }}>
        VS
      </Typography>
      <PlayerNames team={teams.teamB} />
    </Box>
  );
};

export default MultiplePlayerDisplay;
