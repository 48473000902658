import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { useAuth } from '../../../contexts/authContext';
import { Roles, hasAccess } from '../../../helpers/access';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const EventCard = ({ event, onEditOpen }) => {
  const { authenticated, currentUserProfile } = useAuth();
  const navigate = useNavigate();

  return (
    <Card sx={{ m: 1 }}>
      <CardHeader
        title={event.name}
        subheader={event.startDate ? moment(event.startDate).format('DD/MM/YYYY') : ''}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Venue: {event.venue?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Game Format: {event.gameFormat?.name}
        </Typography>
      </CardContent>
      <CardActions>
        {authenticated ? (
          <>
            {hasAccess(currentUserProfile, Roles.ADMIN) &&
              (onEditOpen ? (
                <Button onClick={() => onEditOpen(event)} color="info">
                  Edit
                </Button>
              ) : null)}
            <Button onClick={() => navigate(`/events/${event.id}`)}>View</Button>
          </>
        ) : null}
      </CardActions>
    </Card>
  );
};

export default EventCard;
