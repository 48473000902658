import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Box,
  Typography,
  TextField
} from '@mui/material';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import { inputSetScore } from '../../features/game/gameSlice';
import TeamNameHeaders from '../ui/playerDisplay/teamNameHeaders';

import { useTheme } from '@mui/material/styles';

const schema = yup.object().shape({
  teamATotalScore: yup.number().required('Team 1 Score is required'),
  teamBTotalScore: yup.number().required('Team 2 Score is required'),
  selectedSet: yup.number().required('Sets is required').default(1)
});

const InputScoreDialog = ({ game, onOpen }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const totalSets = game.gameFormat.sets;

  const teamA = game.teams.teamA;
  const teamB = game.teams.teamB;

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = (data) => {
    data.game = game;
    data.gameId = game.id;

    if (totalSets === 1) {
      data.selectedSet = 1;
    }

    data.totalSetScores = {
      [teamA.id]: data.teamATotalScore,
      [teamB.id]: data.teamBTotalScore
    };

    dispatch(inputSetScore(data))
      .unwrap()
      .then(() => {
        setOpen(false);
        onOpen();
      });
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => handleClickOpen()}>
        Input Score
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Score Game - {game.name ?? 'Private Game'}</DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          {totalSets > 1 && (
            <Select control={control} label="Sets" name="selectedSet">
              {Array.from({ length: game.gameFormat.sets }, (_, index) => (
                <MenuItem key={index} value={index + 1}>
                  Set {index + 1}
                </MenuItem>
              ))}
            </Select>
          )}

          <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'}>
            <Paper
              sx={{
                width: '160px',
                height: '160px',
                alignSelf: 'center',
                borderRadius: 2,
                p: 1,
                backgroundColor: 'background.main'
              }}>
              <TeamNameHeaders team={teamA}>
                <TextField
                  variant="standard"
                  fullWidth
                  defaultValue={
                    currentSet.totalSetScores ? currentSet.totalSetScores[teamA.id] : null
                  }
                  error={!!errors.teamATotalScore}
                  sx={{
                    height: '100px',
                    mt: 2,
                    '& .MuiInputBase-input': {
                      textAlign: 'center'
                    },
                    '& .MuiInput-underline': {
                      '&:before': {
                        borderBottomWidth: '2px',
                        borderRadius: '10px'
                      },
                      '&:after': {
                        borderBottomWidth: '3px',
                        borderRadius: '10px'
                      }
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: '1.5rem',
                      backgroundColor: theme.palette.background.paper,
                      padding: '5px',
                      borderRadius: '10px'
                    }
                  }}
                  placeholder="0"
                  {...register('teamATotalScore')}
                />
              </TeamNameHeaders>
            </Paper>

            <Paper
              sx={{
                width: '160px',
                height: '160px',
                alignSelf: 'center',
                borderRadius: 2,
                p: 1,
                backgroundColor: 'background.main'
              }}>
              <TeamNameHeaders team={teamB}>
                <TextField
                  variant="standard"
                  fullWidth
                  defaultValue={
                    currentSet.totalSetScores ? currentSet.totalSetScores[teamB.id] : null
                  }
                  error={!!errors.teamBTotalScore}
                  sx={{
                    height: '100px',
                    mt: 2,
                    '& .MuiInputBase-input': {
                      textAlign: 'center'
                    },
                    '& .MuiInput-underline': {
                      '&:before': {
                        // Unfocused underline thickness
                        borderBottomWidth: '2px', // Change thickness
                        borderRadius: '10px'
                      },
                      '&:after': {
                        // Focused underline thickness
                        borderBottomWidth: '3px', // Change thickness when focused
                        borderRadius: '10px'
                      }
                    }
                  }}
                  InputProps={{
                    style: {
                      fontSize: '1.5rem',
                      backgroundColor: theme.palette.background.paper,
                      padding: '5px',
                      borderRadius: '10px'
                    }
                  }}
                  placeholder="0"
                  {...register('teamBTotalScore')}
                />
              </TeamNameHeaders>
            </Paper>
          </Box>

          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mt={2}>
            <Typography variant="caption" color="error">
              {errors.selectedSet?.message}
            </Typography>
            <Typography variant="caption" color="error">
              {errors.teamATotalScore?.message}
            </Typography>
            <Typography variant="caption" color="error">
              {errors.teamBTotalScore?.message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          <Button variant="contained" onClick={() => handleSubmit(submit)()} color="secondary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InputScoreDialog;
