import React, { useEffect, useState, useContext } from 'react';
import { auth } from '../../config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getSignedInUser } from '../../services/user';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initUser);

    return unsubscribe;
  }, []);

  const initUser = async (user) => {
    if (user) {
      const userProflie = await getSignedInUser(user.uid);

      setCurrentUserProfile({ ...userProflie });
      setCurrentUser({ ...user });
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }

    setLoading(false);
  };

  const value = {
    currentUserProfile,
    currentUser,
    authenticated,
    loading
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

export { AuthProvider };
