import { Stack, Typography, Box } from '@mui/material';
import ScoreBox from './scoreBox';
import ColorDot from '../colorDot';
import EmojiOutcomeBox from './emojiOutcomeBox';

const TeamFinalScore = ({ team, game }) => {
  const teamName = team.players
    .map((player) => {
      if (team.players.length > 2) {
        return player.split(' ')[0].charAt(0) + '. ' + player.split(' ')[1];
      } else {
        return player;
      }
    })
    .join(', ');

  return (
    <Box display="flex" flexDirection={'column'}>
      <ColorDot color={team.color} />
      <Stack px={3} alignItems={'center'} justifyContent={'center'}>
        <Typography variant="caption">{teamName}</Typography>
        {game.teamTotals && (
          <EmojiOutcomeBox
            score={game.teamTotals[team.id]}
            emoji={
              game.winningTeamId === team.id ? '🏆' : game.winningTeamId === null ? '🏅' : '🥈'
            }
          />
        )}
      </Stack>
    </Box>
  );
};

export default TeamFinalScore;
