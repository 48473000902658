import React, { Fragment, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem,
  Typography,
  CircularProgress,
  Button,
  Fab,
  useTheme,
  Paper
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';

import PrimaryButton from '../ui/buttons/primaryButton';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Select from '../ui/forms/fields/select';
import gameFormatService, { GameType } from '../../features/gameFormat/gameFormatService';
import EventMultipleVs from './eventMultipleVs';
import { addEventGame } from '../../features/event/eventSlice';
import GreenSelector from '../ui/clubControls/greenSelector';
import { createGame } from '../../features/game/gameSlice';
import EventSinglesVs from './eventSinglesVs';
import OverrideGameFormat from '../gameFormats/override';

import AddIcon from '@mui/icons-material/Add';
import CustomMultipleVs from './customMultipleVs';
import { getAllGameFormats } from '../../features/gameFormat/gameFormatSlice';
import GameFormatDescription from '../gameFormats/description';
import MultiplePlayerDisplay from '../ui/playerDisplay/multiple';

const schema = yup.object().shape({
  gameFormat: yup.string().required('Game format is required'),
  gameType: yup.number().required('Game type is required'),
  teams: yup.mixed(),
  teamAColor: yup.string(),
  teamBColor: yup.string()
});

const AddPrivateGameDialog = ({ fab }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const gameFormats = useSelector((state) => state.gameFormat.gameFormats);
  const loadingGameFormats = useSelector((state) => state.gameFormat.loading);
  const creatingGame = useSelector((state) => state.game.creatingGame);
  const [selectedGameFormat, setSelectedGameFormat] = useState();

  const isMobile = theme.breakpoints.down('sm') ? true : false;

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const errors = methods.formState.errors;

  useEffect(() => {
    dispatch(getAllGameFormats());
  }, [dispatch]);

  const watchGameFormat = methods.watch('gameFormat');
  const watchGameType = methods.watch('gameType');

  useEffect(() => {
    if (watchGameFormat) {
      setSelectedGameFormat(gameFormats.find((gameFormat) => gameFormat.id === watchGameFormat));
    }
  }, [watchGameFormat, gameFormats]);

  useEffect(() => {
    // Update selected Game Format game type
    if (watchGameType) {
      setSelectedGameFormat((prev) => {
        return {
          ...prev,
          gameType: watchGameType
        };
      });
    }
  }, [watchGameType]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationCheck = (data) => {
    const teams = data.teams;
    const gamePlayerLimit = gameFormatService.getGameTypeLimit(data.gameType) / 2;

    const isTeamAEmpty = teams.teamA.players.length === 0;
    const isTeamBEmpty = teams.teamB.players.length === 0;
    const isTeamAValid = teams.teamA.players.length === gamePlayerLimit;
    const isTeamBValid = teams.teamB.players.length === gamePlayerLimit;

    if (isTeamAEmpty || isTeamBEmpty) {
      if (isTeamAEmpty) {
        methods.setError('teams', {
          type: 'manual',
          message: 'Team A must have players'
        });
      } else {
        methods.setError('teams', {
          type: 'manual',
          message: 'Teams B must have players'
        });
      }

      return false;
    }

    if (!isTeamAValid || !isTeamBValid) {
      if (!isTeamAValid) {
        methods.setError('teams', {
          type: 'manual',
          message: `Team A has only ${teams.teamA.players.length} out of ${gamePlayerLimit} players. Each team must have ${gamePlayerLimit} players`
        });
      } else {
        methods.setError('teams', {
          type: 'manual',
          message: `Team B has only ${teams.teamB.players.length} out of ${gamePlayerLimit} players. Each team must have ${gamePlayerLimit} players`
        });
      }
      return false;
    }
    return true;
  };

  const onSubmit = (data) => {
    delete data.teamAColor;
    delete data.teamBColor;

    data.gameFormat = selectedGameFormat;

    if (validationCheck(data)) {
      dispatch(createGame(data))
        .unwrap()
        .then((response) => {
          setOpen(false);
        });
    }
  };

  return (
    <Fragment>
      {!fab ? (
        <PrimaryButton variant={'contained'} onClick={() => handleClickOpen()}>
          Add Game
        </PrimaryButton>
      ) : (
        <Fab color="primary" aria-label="add" onClick={() => handleClickOpen()}>
          <AddIcon />
        </Fab>
      )}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" fullScreen={isMobile}>
        <DialogTitle textAlign={'center'}>Add Game</DialogTitle>
        <DialogContent>
          {loadingGameFormats ? (
            <CircularProgress />
          ) : (
            <FormProvider {...methods}>
              <Select name="gameFormat" label="Game Format" error={errors.gameFormat}>
                {gameFormats.map((gameFormat) => (
                  <MenuItem key={gameFormat.id} value={gameFormat.id}>
                    {gameFormat.name}
                  </MenuItem>
                ))}
              </Select>
              <Select name="gameType" label="Game Type" error={errors.gameType}>
                <MenuItem value={2}>Singles</MenuItem>
                <MenuItem value={1}>Pairs</MenuItem>
                <MenuItem value={3}>Triples</MenuItem>
                <MenuItem value={4}>Quads</MenuItem>
              </Select>

              <CustomMultipleVs gameType={selectedGameFormat?.gameType} />
            </FormProvider>
          )}
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="h6" color="primary">
              Game Summary
            </Typography>
            {watchGameFormat !== '' && watchGameFormat !== undefined ? (
              <>
                <Typography variant="h6" mb={2}>
                  {gameFormatService.getGameTypeString(watchGameType)} - {selectedGameFormat?.name}
                </Typography>

                {selectedGameFormat && (
                  <>
                    <GameFormatDescription gameFormat={selectedGameFormat} />
                    <OverrideGameFormat
                      gameFormat={selectedGameFormat}
                      onChange={(gf) => setSelectedGameFormat(gf)}
                    />
                  </>
                )}
              </>
            ) : (
              <Typography m={2}>No Information yet... Start by selecting a Game Format</Typography>
            )}

            {methods.watch('teams')?.teamA?.players.length > 0 &&
            methods.watch('teams')?.teamB?.players.length > 0 ? (
              <Box my={2}>
                <Typography variant="h6" color="primary" mb={1}>
                  Players & Teams
                </Typography>

                <MultiplePlayerDisplay teams={methods.watch('teams')} />
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} flexDirection={'row'}>
            <Box display={'flex'} justifyContent={'flex-end'} my={2}>
              {errors.teams && errors.teams.message && (
                <Typography variant="caption" color="error">
                  {errors.teams && errors.teams.message}
                </Typography>
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              width="100%"
              justifyContent={'flex-end'}
              gap={1}>
              <Button onClick={handleClose}>Cancel</Button>
              {creatingGame ? (
                <CircularProgress />
              ) : (
                <PrimaryButton
                  variant="contained"
                  color="primary"
                  sx={{ my: 2 }}
                  onClick={() => methods.handleSubmit(onSubmit)()}>
                  Save
                </PrimaryButton>
              )}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddPrivateGameDialog;
