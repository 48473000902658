import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, CircularProgress, useTheme, Fab, IconButton } from '@mui/material';
import { getGamesByUserId } from '../../features/game/gameSlice';
import GameNotStartedCard from '../ui/gameStatusCards/gameNotStartedCard';
import GameByStatusCards from '../ui/gameStatusCards/gameByStatusCards';
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { auth } from '../../config/firebase';

import { useTitle } from '../../hooks/titleProvider';

import AddIcon from '@mui/icons-material/Add';
import AddGameDialog from '../games/addGameDialog';
import AddPrivateGameDialog from '../games/addPrivateGameDialog';

const Dashboard = () => {
  //const dispatch = useDispatch();
  const theme = useTheme();

  //const games = useSelector((state) => state.game.games);
  //const loading = useSelector((state) => state.game.loading);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const { uid } = auth.currentUser;

  const isMobile = theme.breakpoints.down('sm');

  const { setTitle } = useTitle();
  setTitle('Home');

  //Get games by user id strait from the store
  useEffect(() => {
    if (!uid) {
      setLoading(false);
      return; // Ensure uid is available
    }

    const q = query(collection(store, 'games'), where('uid', '==', uid));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const games = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.event && !data.deleted) {
          // Check if 'event' is null or undefined
          games.push({ id: doc.id, ...data });
        }
      });
      setGames(games);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [uid]);

  const handleNewGame = () => {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Typography variant="h5" component="h1" mb={1}>
        Private Games
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          {games.length === 0 && <Typography variant="caption">No games found</Typography>}
          {games.map((game, index) => (
            <Fragment key={game.id}>
              <GameByStatusCards game={game} />
            </Fragment>
          ))}
        </Box>
      )}

      <Box sx={{ position: 'relative' }}>
        <Box display="flex" gap={1} sx={{ position: 'fixed', bottom: 64, right: 16 }}>
          <AddPrivateGameDialog fab />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
