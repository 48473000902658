import { Box, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import PrimaryButton from '../buttons/primaryButton';
import { Fragment } from 'react';
import AddGameDialog from '../../games/addGameDialog';

import { useAuth } from '../../../contexts/authContext';
import { Roles, hasAccess } from '../../../helpers/access';

const EventSectionCard = ({ event, section, onViewSection }) => {
  const { authenticated, currentUserProfile } = useAuth();

  const getAllPlayersInSection = () => {
    //TODO Add singles option
    const teams = event.teams;
    const sectionGames = section.games;
    const teamPlayerNames = [];

    if (sectionGames && sectionGames.length > 0) {
      for (let i = 0; i < sectionGames.length; i++) {
        const game = sectionGames[i];

        const teamA = teams.find((t) => t.id === game.teamA);
        const teamB = teams.find((t) => t.id === game.teamB);

        if (teamPlayerNames.indexOf(teamA.name) === -1) teamPlayerNames.push(teamA.name);

        if (teamPlayerNames.indexOf(teamB.name) === -1) teamPlayerNames.push(teamB.name);
      }
    }

    return teamPlayerNames;
  };

  return (
    <Fragment>
      <Card sx={{ minWidth: 350, mb: 1, mr: 2 }}>
        <CardHeader title={section.name} />
        <CardContent>
          <Typography variant={'h6'}>Players:</Typography>
          {getAllPlayersInSection().map((player, index) => (
            <Typography key={index} variant="body2">
              {index + 1}. {player}
            </Typography>
          ))}
        </CardContent>
        <CardActions
          sx={{ justifyContent: 'space-between', alignItems: 'center', justifySelf: 'flex-end' }}>
          {section.games && (
            <Typography variant={'caption'}>
              Total Games: {section.games ? section.games.length : 0}
            </Typography>
          )}

          <Box display={'flex'} flexDirection={'row'}>
            {hasAccess(currentUserProfile, Roles.ADMIN) && (
              <AddGameDialog event={event} section={section} />
            )}
            {section.games && (
              <PrimaryButton color="info" onClick={() => onViewSection()}>
                View Games
              </PrimaryButton>
            )}
          </Box>
        </CardActions>
      </Card>
    </Fragment>
  );
};

export default EventSectionCard;
