export const hasAccess = (user, role) => {
  if (user && user.role) {
    if (user.selectedClub && user.selectedClub !== user.homeClub) {
      const { clubs } = user;
      const club = clubs.find((c) => c.id === user.selectedClub);
      return hasRole(club.role).indexOf(role) > -1;
    }

    return hasRole(user.role).indexOf(role) > -1;
  }
};

export const hasRole = (role) => {
  switch (role) {
    case 'superuser':
      return ['superuser', 'admin', 'scorer', 'user'];
    case 'admin':
      return ['admin', 'scorer', 'user'];
    case 'scorer':
      return ['scorer', 'user'];
    case 'user':
      return ['user'];
    default:
      return [];
  }
};

export const Roles = {
  SUPERUSER: 'superuser',
  ADMIN: 'admin',
  SCORER: 'scorer',
  USER: 'user'
};
