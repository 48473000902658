import { Badge, Box, Typography } from '@mui/material';

const EmojiOutcomeBox = ({ score, emoji, teamTotal }) => (
  <Box display="flex" justifyContent={'center'} alignItems={'center'}>
    {/* <Badge
      badgeContent={<Typography variant="caption">{score}</Typography>}
      showZero={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      
    </Badge> */}
    <Typography variant="h2" role="img" aria-label="Trophy">
      {emoji}
    </Typography>
  </Box>
);

export default EmojiOutcomeBox;
