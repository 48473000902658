//Login Page - Using firebase Password/Email Login

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box, Container, Divider, Paper, Typography } from '@mui/material';
import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';
import { signInUser } from '../../services/auth';
import { useAlert } from '../../contexts/alertContext';
import { useNavigate } from 'react-router-dom';
import Logo from '../../resources/assets/images/logo192.png';
import AuthTemplate from './authTemplate';
import { getSignedInUser, getUserByEmail } from '../../services/user';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required')
});

const SignIn = () => {
  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const { showAlertSuccess, showAlertError } = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSignIn = async (data) => {
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        const result = await signInUser(data.email, data.password);

        //Check if user profile exists
        if (result && result.user) {
          const user = await getUserByEmail(result.user.email);

          if (!user) {
            navigate('/user/profile');
          }

          showAlertSuccess('Successfully signed in!');
          return;
        }

        showAlertError('Please check user credentials and try again.');
      } catch (err) {
        showAlertError('Please check user credentials and try again.');
      }

      setIsSigningIn(false);
    }
  };

  return (
    <AuthTemplate>
      <Input
        label="Email"
        variant="outlined"
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
      />
      <Input
        label="Password"
        variant="outlined"
        type="password"
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
      />
      <Box display="flex" justifyContent={'center'} alignItems={'center'} my={2}>
        <PrimaryButton variant="contained" color="primary" onClick={() => handleSubmit(onSignIn)()}>
          Login
        </PrimaryButton>
        <Typography
          component={'a'}
          href="/forgot-password"
          variant="caption"
          color="textSecondary"
          align="center"
          mx={1}>
          Forgot password?
        </Typography>
      </Box>
      <Divider sx={{ m: 2 }} />
      <PrimaryButton variant="outlined" color="info" onClick={() => navigate('/signup')}>
        Sign Up
      </PrimaryButton>
    </AuthTemplate>
  );
};

export default SignIn;
