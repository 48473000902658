import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CircularProgress, Stack } from '@mui/material';
import { GameStatus } from '../../../constants/gameConstants';
import { startNewSet, startPlayoff } from '../../../features/game/gameSlice';

const GameControlSecondary = ({ game }) => {
  const dispatch = useDispatch();

  const hasSets = game.gameFormat.sets > 1 && game.status !== GameStatus.PLAYOFF;
  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const startingNewSet = useSelector((state) => state.game.startingNewSet);
  const startingPlayoff = useSelector((state) => state.game.startingPlayoff);

  const handleNextSet = () => {
    dispatch(startNewSet(game.id));
  };

  const handleStartPlayoff = () => {
    dispatch(startPlayoff(game.id));
  };

  return (
    <Stack alignItems={'center'}>
      {hasSets && currentSet.isDone && game.status !== GameStatus.COMPLETE ? (
        startingNewSet ? (
          <CircularProgress color={'blue'} />
        ) : (
          <Button color={'blue'} onClick={handleNextSet} sx={{ mt: 2 }} variant="outlined">
            Start Next Set
          </Button>
        )
      ) : null}

      {game.status === GameStatus.PLAYOFF && !game.playoff ? (
        startingPlayoff ? (
          <CircularProgress color={'warning'} />
        ) : (
          <Button
            color={'warning'}
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => handleStartPlayoff()}>
            Start Playoff
          </Button>
        )
      ) : null}
    </Stack>
  );
};

export default GameControlSecondary;
