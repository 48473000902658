import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";

// const useStyles = makeStyles((theme) => ({
//   customOutlinedInput: {
//     '& .MuiOutlinedInput-notchedOutline': {
//       border: '1.5px solid',
//       borderColor: theme.palette.text.main,
//       borderRadius: '7px'
//     },
//     '& .MuiSelect-icon': {
//       color: theme.palette.text.main
//     }
//   },
//   label: {
//     color: theme.palette.text.main,
//     fontWeight: 'bold'
//   }
// }));

const PrimarySelect = ({
  name,
  label,
  control,
  defaultValue,
  helperText,
  children,
  ...props
}) => {
  const labelId = `${name}-label`;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl sx={{ mt: 1, mb: 0.5 }} fullWidth size="small" {...props}>
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            labelId={labelId}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          >
            {children}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default PrimarySelect;
