import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Stack,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
  TableBody,
  Button,
  Tabs,
  Tab
} from '@mui/material';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { setLoadingEvent, setEvent } from '../../features/event/eventSlice';

import { doc, onSnapshot } from 'firebase/firestore';
import { store } from '../../config/firebase';
import { useColorMode } from '../../hooks/colorModeProvider';
import PoweredBy from '../ui/poweredBy';
import { getEventSectionsLeaderboard } from '../../features/leaderboard/leaderboardSlice';
import BSCircularProgress from '../ui/progress/circularProgress';
import SectionTotalsCard from '../ui/scoreboard/sectionTotalsCard';
import SectionScoreCard from '../ui/scoreboard/sectionScorecard';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: 'none',
    fontSize: 12
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const EmbeddedDetailedSectionsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector((state) => state.event.event);
  const loading = useSelector((state) => state.event.loading);

  const loadingLeaderboard = useSelector((state) => state.leaderboard.loading);
  const sectionsLeaderboard = useSelector((state) => state.leaderboard.sectionLeaderboard);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const { mode, setMode } = useColorMode();

  useEffect(() => {
    setMode('light');
  }, [mode, setMode]);

  //Get Event ID from params.
  const { eventId } = useParams();

  //Get event by eventId
  useEffect(() => {
    if (!eventId) return;

    dispatch(setLoadingEvent(true));
    const docRef = doc(store, 'events', eventId);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const eventData = { id: docSnap.id, ...docSnap.data() };
        dispatch(setEvent(eventData));
      } else {
        console.log('No such document!');
      }
      dispatch(setLoadingEvent(false));
    });

    return () => unsubscribe();
  }, [eventId, dispatch]);

  useEffect(() => {
    if (event) {
      dispatch(getEventSectionsLeaderboard({ eventId: event.id }));
    }
  }, [dispatch, event]);

  const getTeamsByRounds = (section) => {
    const rounds = [];
    for (let i = 0; i < event.gameFormat.rounds; i++) {
      const teams = section.teams.filter((t) => t.round === i + 1);

      if (teams.length > 0) {
        rounds.push({ teams, round: i + 1 });
      }
    }

    return rounds;
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Typography variant="h5" mt={2}>
        Scores
      </Typography>
      {loadingLeaderboard || loading ? (
        <BSCircularProgress caption={'Loading...'} size={30} />
      ) : (
        sectionsLeaderboard && (
          <Box sx={{ width: '100%' }}>
            <Box display={'flex'} justifyContent={'center'}>
              <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)}>
                <Tab label="Games" value={0} />
                {event.publishLogs && <Tab label="Log" value={1} />}

                {event.publishScorecard && <Tab label="Detailed Scorecard" value={2} />}
              </Tabs>
            </Box>

            <CustomTabPanel value={selectedTab} index={0}>
              <Box
                sx={{ width: '100%' }}
                display={'flex'}
                flexDirection={'row'}
                gap={3}
                justifyContent={'center'}
                flexWrap={'wrap'}
                py={2}>
                <Stack>
                  {event.sections.map((section) => (
                    <TableContainer
                      key={section.id}
                      component={Paper}
                      sx={{
                        m: 2,
                        borderRadius: 3,
                        maxWidth: 400,
                        border: '1px solid',
                        borderColor: 'primary.main'
                      }}>
                      <Table padding="none">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              padding="normal"
                              sx={{ fontWeight: 'bold' }}
                              colSpan={2}>
                              {section.name}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <StyledTableCell padding="normal" align="right">
                              <Typography>
                                Total Games: {section.games ? section.games.length : 0}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell padding="normal" align="right">
                              <Button
                                variant="contained"
                                color="info"
                                onClick={() => navigate(`/embed/games/${section.id}`)}>
                                View Games
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                </Stack>
              </Box>
            </CustomTabPanel>
            {event.publishLogs && (
              <CustomTabPanel value={selectedTab} index={1}>
                <Box
                  sx={{ width: '100%' }}
                  display={'flex'}
                  flexDirection={'row'}
                  gap={3}
                  justifyContent={'center'}
                  flexWrap={'wrap'}
                  py={2}>
                  {sectionsLeaderboard.map((section, index) => (
                    <Fragment key={index}>
                      <SectionTotalsCard section={section} />
                    </Fragment>
                  ))}
                </Box>
              </CustomTabPanel>
            )}
            {event.publishScorecard && (
              <CustomTabPanel value={selectedTab} index={2}>
                {sectionsLeaderboard.map((section) => (
                  <Box
                    key={section.id}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}>
                    <Typography variant="h5" my={2}>
                      {section.sectionName}
                    </Typography>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                      gap={1}
                      flexWrap={'wrap'}>
                      {(() => {
                        const teamRounds = getTeamsByRounds(section);

                        if (teamRounds.length === 0) {
                          return (
                            <Typography>No data available yet, please check back later.</Typography>
                          );
                        } else {
                          return teamRounds.map((tr, index) => (
                            <Fragment key={index}>
                              <SectionScoreCard teams={tr.teams} round={tr.round} />
                            </Fragment>
                          ));
                        }
                      })()}
                    </Box>
                  </Box>
                ))}
              </CustomTabPanel>
            )}
          </Box>
        )
      )}
      <PoweredBy />
    </Box>
  );
  // return (
  //   <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  //     {loading ? (
  //       <Typography>Loading...</Typography>
  //     ) : (
  //       <>
  //         <Typography variant="h4" textAlign="center" mt={2}>
  //           Sections
  //         </Typography>

  //         <Stack>
  //           {event.sections.map((section) => (
  //             <TableContainer
  //               key={section.id}
  //               component={Paper}
  //               sx={{
  //                 m: 2,
  //                 borderRadius: 3,
  //                 maxWidth: 400,
  //                 border: '1px solid',
  //                 borderColor: 'primary.main'
  //               }}>
  //               <Table padding="none">
  //                 <TableHead>
  //                   <TableRow>
  //                     <StyledTableCell padding="normal" sx={{ fontWeight: 'bold' }} colSpan={2}>
  //                       {section.name}
  //                     </StyledTableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <TableBody>
  //                   <TableRow>
  //                     <StyledTableCell padding="normal" align="right">
  //                       <Typography>
  //                         Total Games: {section.games ? section.games.length : 0}
  //                       </Typography>
  //                     </StyledTableCell>
  //                     <StyledTableCell padding="normal" align="right">
  //                       <Button
  //                         variant="contained"
  //                         color="info"
  //                         onClick={() => navigate(`/embed/games/${section.id}`)}>
  //                         View Games
  //                       </Button>
  //                     </StyledTableCell>
  //                   </TableRow>
  //                 </TableBody>
  //               </Table>
  //             </TableContainer>
  //           ))}
  //         </Stack>

  //         <PoweredBy />
  //       </>
  //     )}
  //   </Box>
  // );
};

export default EmbeddedDetailedSectionsView;
