import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

const StyledSectionTotalTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.common.white,
    borderBottom: 'none'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    borderBottom: 'none'
  }
}));

const TotalsCard = ({ teamScores }) => {
  if (teamScores && teamScores.length !== 0) {
    let teamTotals = teamScores;
    const mutableTeamSetTotals = Array.from(teamTotals);
    mutableTeamSetTotals.sort((a, b) => b.totalPoints - a.totalPoints);
    teamTotals = mutableTeamSetTotals;

    console.log(teamTotals);

    return (
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 3,
          maxWidth: 400,
          border: '1px solid',
          borderColor: 'blue.main'
        }}>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <StyledSectionTotalTableCell padding="normal">
                <Typography variant="body1" fontWeight={'bold'}>
                  Game
                </Typography>
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                Total
              </StyledSectionTotalTableCell>
              <StyledSectionTotalTableCell padding="normal" align={'center'}>
                Net
              </StyledSectionTotalTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamTotals.map((team) => (
              <TableRow key={team.teamId}>
                <StyledSectionTotalTableCell size="small" padding="normal">
                  {team.teamName}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalPoints}
                </StyledSectionTotalTableCell>
                <StyledSectionTotalTableCell size="small" align={'center'}>
                  {team.totalNet}
                </StyledSectionTotalTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <Box>
        <Typography variant="h6">Totals</Typography>
        <Typography variant="body1">No data available</Typography>
      </Box>
    );
  }
};

export default TotalsCard;
