/* eslint-disable react-hooks/exhaustive-deps */
import { Box, MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from '../ui/forms/fields/select';
import TeamColorPicker from '../ui/teamControls/teamColorPicker';

const EventSinglesVs = () => {
  const eventPlayers = useSelector((state) => state.event.event.players);

  const {
    register,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useFormContext();

  const [teamA, setTeamA] = useState({ players: [], color: 'green' });
  const [teamB, setTeamB] = useState({ players: [], color: 'red' });
  const [teams, setTeams] = useState({});

  useEffect(() => {
    register('teams');
  }, [register]);

  useEffect(() => {
    setTeams({ teamA, teamB });
  }, [teamA, teamB]);

  useEffect(() => {
    setValue('teams', teams);
  }, [teams, setValue]);

  const watchTeamAColor = watch('teamAColor');
  const watchTeamBColor = watch('teamBColor');

  useEffect(() => {
    setTeamA({ ...teamA, color: watchTeamAColor });
  }, [watchTeamAColor]);

  useEffect(() => {
    setTeamB({ ...teamB, color: watchTeamBColor });
  }, [watchTeamBColor]);

  const watchTeamA = watch('teamA');
  const watchTeamB = watch('teamB');

  useEffect(() => {
    if (watchTeamA) {
      onTeamChange(watchTeamA, 'teamA');
    }
  }, [watchTeamA]);

  useEffect(() => {
    if (watchTeamB) {
      onTeamChange(watchTeamB, 'teamB');
    }
  }, [watchTeamB]);

  const onTeamChange = (value, team) => {
    const selectedPlayer = eventPlayers.find((player) => player.id === value);
    const playerStringName = `${selectedPlayer.name} ${selectedPlayer.surname}`;

    if (team === 'teamA') {
      setTeamA({ ...teamA, players: [playerStringName] });
    } else {
      setTeamB({ ...teamB, players: [playerStringName] });
    }
  };

  return (
    eventPlayers &&
    eventPlayers.length > 0 && (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={'space-evenly'}
        alignItems={'center'}
        gap={1}>
        <Select name="teamA" control={control} error={errors.teamA}>
          {eventPlayers.map((player) => (
            <MenuItem key={player.id} value={player.id}>
              {player.name} {player.surname}
            </MenuItem>
          ))}
        </Select>
        <TeamColorPicker name={'teamAColor'} defaultValue={'green'} />
        <Typography variant="h6" color="primary" fontWeight="bold" sx={{ mx: 2 }}>
          VS
        </Typography>
        <Select name="teamB" control={control} error={errors.teamB}>
          {eventPlayers.map((player) => (
            <MenuItem key={player.id} value={player.id}>
              {player.name} {player.surname}
            </MenuItem>
          ))}
        </Select>
        <TeamColorPicker name={'teamBColor'} defaultValue={'red'} />
      </Box>
      // <Box display="flex" flexDirection="row" justifyContent={'space-evenly'} alignItems={'center'}>
      //   <Select name="teamA" control={control} error={errors.teamA}>
      //     {eventPlayers.map((player) => (
      //       <MenuItem key={player.id} value={player.id}>
      //         {player.name}
      //       </MenuItem>
      //     ))}
      //   </Select>
      //   <Typography variant="h6" color="primary" fontWeight="bold" sx={{ mx: 2 }}>
      //     VS
      //   </Typography>
      //   <Select name="teamB" control={control} error={errors.teamB}>
      //     {eventPlayers.map((player) => (
      //       <MenuItem key={player.id} value={player.id}>
      //         {player.name}
      //       </MenuItem>
      //     ))}
      //   </Select>
      // </Box>
    )
  );
};

export default EventSinglesVs;
