import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { ImportExport } from '@mui/icons-material';
import { getCMEvent, getCMEvents } from '../../features/event/eventSlice';

import BSCircularProgress from '../ui/progress/circularProgress';
import moment from 'moment';
import { convertFirebaseDate } from '../../helpers/dateHelper';

const ImportEventDialog = ({ onImport }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  const club = useSelector((state) => state.venue.selectedVenue);
  const loadingEvent = useSelector((state) => state.event.clubmaster.loadingEvent);
  const loadingEvents = useSelector((state) => state.event.clubmaster.loadingEvents);

  const event = useSelector((state) => state.event.clubmaster.event);
  const events = useSelector((state) => state.event.clubmaster.events);

  useEffect(() => {
    if (club) {
      dispatch(getCMEvents({ cmClubId: club.clubMasterId }));
    }
  }, [dispatch, club]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVenueChange = (e) => {
    const selEvent = events.find((ev) => ev.id === e.target.value);
    setSelectedEvent(selEvent);
    dispatch(getCMEvent({ cmEventId: selEvent.id }));
  };

  const handleImport = () => {
    if (event) {
      onImport({
        ...event,
        startDate: convertFirebaseDate(event.startDate),
        endDate: convertFirebaseDate(event.endDate)
      });
      handleClose();
    }
  };

  return (
    <Fragment>
      <IconButton onClick={() => handleOpen()}>
        <ImportExport color={'primary'} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle>Import Event</DialogTitle>
        <DialogContent>
          <Typography>Import Event From Fairtree Club Master.</Typography>
          <Box my={2}>
            {loadingEvents ? (
              <BSCircularProgress caption={'Loading Events from Club Master'} />
            ) : (
              <FormControl fullWidth>
                <InputLabel id="select-small-label">Select Club Master Event</InputLabel>
                <Select
                  fullWidth
                  name="selectCMEvent"
                  labelId="select-small-label"
                  onChange={handleVenueChange}
                  value={selectedEvent.id}>
                  {events &&
                    events.map(
                      (event, index) =>
                        moment(event.startDate).isAfter(moment()) && (
                          <MenuItem key={index} value={event.id}>
                            <Box display={'flex'} flexDirection="column">
                              {event.name}
                              <Typography variant="caption">
                                {moment(event.startDate).format('DD-MM-YYYY')}
                              </Typography>
                            </Box>
                          </MenuItem>
                        )
                    )}
                </Select>
              </FormControl>
            )}
          </Box>

          <Box my={2}>
            {selectedEvent && loadingEvent ? (
              <BSCircularProgress caption={'Fetching Event Detail from Club Master'} />
            ) : (
              event && (
                <Box>
                  <Typography variant="h4">{event.name}</Typography>
                  <Typography>Venue: {event.venue}</Typography>
                  <Typography>District: {event.district}</Typography>
                  <Typography>
                    Start Date: {moment(convertFirebaseDate(event.startDate)).format('DD-MM-YYYY')}
                  </Typography>
                  <Typography>
                    End Date: {moment(convertFirebaseDate(event.endDate)).format('DD-MM-YYYY')}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={() => handleImport()}>
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ImportEventDialog;
