import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import gameService from './gameService';

export const getAllGames = createAsyncThunk('game/getAllGames', async (data, thunkAPI) => {
  return await gameService
    .get()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getGameById = createAsyncThunk('game/getGameById', async (data, thunkAPI) => {
  return await gameService
    .getById(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getGameByEventId = createAsyncThunk(
  'game/getGameByEventId',
  async (data, thunkAPI) => {
    return await gameService
      .getByEventId(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getGameBySectionId = createAsyncThunk(
  'game/getGameBySectionId',
  async (data, thunkAPI) => {
    return await gameService
      .getBySectionId(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const getGamesByUserId = createAsyncThunk(
  'game/getGamesByUserId',
  async (data, thunkAPI) => {
    return await gameService
      .getByUserId()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const createGame = createAsyncThunk('game/createGame', async (data, thunkAPI) => {
  return await gameService
    .create(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const updateGame = createAsyncThunk('game/updateGame', async (data, thunkAPI) => {
  return await gameService
    .update(data.id, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const deleteGame = createAsyncThunk('game/deleteGame', async (data, thunkAPI) => {
  return await gameService
    .remove(data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const startGame = createAsyncThunk('game/startGame', async (data, thunkAPI) => {
  return await gameService.startGame(data).then((response) => {
    return response;
  });
});

export const scoreGame = createAsyncThunk('game/scoreGame', async (data, thunkAPI) => {
  return await gameService.scoreGame(data).then((response) => {
    return response;
  });
});

export const undoLastScore = createAsyncThunk('game/undoLastScore', async (data, thunkAPI) => {
  return await gameService.undoLastScore(data).then((response) => {
    return response;
  });
});

export const startNewSet = createAsyncThunk('game/startNewSet', async (data, thunkAPI) => {
  return await gameService.startNewSet(data).then((response) => {
    return response;
  });
});

export const startPlayoff = createAsyncThunk('game/startPlayoff', async (data, thunkAPI) => {
  return await gameService.startPlayoff(data).then((response) => {
    return response;
  });
});

export const scorePlayoffGame = createAsyncThunk(
  'game/scorePlayoffGame',
  async (data, thunkAPI) => {
    return await gameService.scorePlayoffGame(data).then((response) => {
      return response;
    });
  }
);

export const undoPlayoffScore = createAsyncThunk(
  'game/undoPlayoffScore',
  async (data, thunkAPI) => {
    return await gameService.undoPlayoffScore(data).then((response) => {
      return response;
    });
  }
);

export const inputSetScore = createAsyncThunk('game/inputSetScore', async (data, thunkAPI) => {
  return await gameService.inputSetScore(data).then((response) => {
    return response;
  });
});

const gameSlice = createSlice({
  name: 'game',
  initialState: {
    games: [],
    status: 'idle',
    error: null,
    creatingGame: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllGames.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllGames.fulfilled, (state, action) => {
        state.loading = false;
        state.games = state.games.concat(action.payload);
      })
      .addCase(getAllGames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getGameById.fulfilled, (state, action) => {
        state.game = action.payload;
      })
      .addCase(getGameByEventId.fulfilled, (state, action) => {
        state.games = action.payload;
      })
      .addCase(getGameByEventId.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getGameBySectionId.fulfilled, (state, action) => {
        state.games = action.payload;
        state.loading = false;
      })
      .addCase(getGameBySectionId.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getGameBySectionId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGamesByUserId.fulfilled, (state, action) => {
        state.games = action.payload;
        state.loading = false;
      })
      .addCase(getGamesByUserId.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getGamesByUserId.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGame.fulfilled, (state, action) => {
        state.creatingGame = false;
        state.games.push(action.payload);
      })
      .addCase(createGame.rejected, (state, action) => {
        state.creatingGame = false;
        state.error = action.payload;
      })
      .addCase(createGame.pending, (state) => {
        state.creatingGame = true;
      })
      .addCase(updateGame.fulfilled, (state, action) => {
        const { id } = action.payload;
        let existingGame = state.games.find((game) => game.id === id);
        if (existingGame) {
          existingGame = action.payload;
        }
      })
      .addCase(deleteGame.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.games = state.games.filter((game) => game.id !== id);
        state.deleting = false;
      })
      .addCase(deleteGame.rejected, (state, action) => {
        state.deleting = false;
      })
      .addCase(deleteGame.pending, (state) => {
        state.deleting = true;
      })
      .addCase(startGame.fulfilled, (state, action) => {
        const updatedGame = action.payload;
        if (updatedGame) {
          const index = state.games.findIndex((game) => game.id === updatedGame.id);

          if (index !== -1) {
            state.games[index] = { ...state.games[index], ...updatedGame }; // Merge existing game properties with updated ones
          }
        }

        state.creatingGame = false;
      })
      .addCase(startGame.rejected, (state, action) => {
        state.creatingGame = false;
        state.error = action.payload;
      })
      .addCase(startGame.pending, (state) => {
        state.creatingGame = true;
      })
      .addCase(scoreGame.fulfilled, (state, action) => {
        state.scoringGame = false;
      })
      .addCase(scoreGame.rejected, (state, action) => {
        state.scoringGame = false;
        state.error = action.payload;
      })
      .addCase(scoreGame.pending, (state) => {
        state.scoringGame = true;
      })
      .addCase(undoLastScore.fulfilled, (state, action) => {
        state.undoingScore = false;
      })
      .addCase(undoLastScore.rejected, (state, action) => {
        state.undoingScore = false;
        state.error = action.payload;
      })
      .addCase(undoLastScore.pending, (state) => {
        state.undoingScore = true;
      })
      .addCase(startNewSet.fulfilled, (state, action) => {
        state.startingNewSet = false;
      })
      .addCase(startNewSet.rejected, (state, action) => {
        state.startingNewSet = false;
        state.error = action.payload;
      })
      .addCase(startNewSet.pending, (state) => {
        state.startingNewSet = true;
      })
      .addCase(startPlayoff.fulfilled, (state, action) => {
        state.startingPlayoff = false;
      })
      .addCase(startPlayoff.rejected, (state, action) => {
        state.startingPlayoff = false;
        state.error = action.payload;
      })
      .addCase(startPlayoff.pending, (state) => {
        state.startingPlayoff = true;
      })
      .addCase(scorePlayoffGame.fulfilled, (state, action) => {
        state.scoringGame = false;
      })
      .addCase(scorePlayoffGame.rejected, (state, action) => {
        state.scoringGame = false;
        state.error = action.payload;
      })
      .addCase(scorePlayoffGame.pending, (state) => {
        state.scoringGame = true;
      })
      .addCase(undoPlayoffScore.fulfilled, (state, action) => {
        state.undoingPlayoffScore = false;
      })
      .addCase(undoPlayoffScore.rejected, (state, action) => {
        state.undoingPlayoffScore = false;
        state.error = action.payload;
      })
      .addCase(undoPlayoffScore.pending, (state) => {
        state.undoingPlayoffScore = true;
      })
      .addCase(inputSetScore.fulfilled, (state, action) => {
        const updatedGame = action.payload;
        if (updatedGame) {
          const index = state.games.findIndex((game) => game.id === updatedGame.id);

          if (index !== -1) {
            state.games[index] = { ...state.games[index], ...updatedGame }; // Merge existing game properties with updated ones
          }
        }

        state.inputtingSetScore = false;
      })
      .addCase(inputSetScore.rejected, (state, action) => {
        state.inputtingSetScore = false;
        state.error = action.payload;
      })
      .addCase(inputSetScore.pending, (state) => {
        state.inputtingSetScore = true;
      });
  }
});

export default gameSlice.reducer;
