import { collection, doc, getDocs } from 'firebase/firestore';
import { store } from '../../config/firebase';
import axiosInstance from '../axiosInstance';

//Game Format
/**
 *
 * ends
 * gameType - Int
 * includeHandicap - Bool
 * lossPoints - Int
 * maxScore - Int
 * maxTotalPerSet - Int
 * name - String
 * numBowls - Int
 * playoffMaxPoints - Int
 * playoffPoint - Int
 * playoffs - Bool
 * sets - Int
 * winPoints - Int
 *
 */

//CRUD Functions
// const getAll = async () => {
//   const collectionRef = collection(store, "gameformats");
//   const snapshot = await getDocs(collectionRef);
//   const gameformats = snapshot.docs.map((doc) => ({
//     id: doc.id,
//     ...doc.data(),
//   }));
//   return gameformats;
// };

const getAll = async () => {
  try {
    const response = await axiosInstance.get('/gameformat');
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getById = async (id) => {
  const docRef = doc(store, 'gameFormats', id);
  const docSnap = await docRef.get();
  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    return null;
  }
};

const GameStatus = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  COMPLETE: 3,
  PLAYOFF: 4
};

const GameType = {
  PAIRS: 1,
  SINGLES: 2,
  TRIPLES: 3,
  QUADS: 4
};

function getGameTypeString(gameType) {
  switch (gameType) {
    case GameType.PAIRS:
      return 'Pairs';
    case GameType.SINGLES:
      return 'Singles';
    case GameType.TRIPLES:
      return 'Triples';
    case GameType.QUADS:
      return 'Quads';
    default:
      return 'Pairs';
  }
}

function getGameTypeLimit(gameType) {
  switch (gameType) {
    case GameType.SINGLES:
      return 2;
    case GameType.PAIRS:
      return 4;
    case GameType.TRIPLES:
      return 6;
    case GameType.QUADS:
      return 8;
    default:
      return 2;
  }
}

const gameFormatService = {
  getById,
  getAll,
  GameStatus,
  GameType,
  getGameTypeString,
  getGameTypeLimit
};

export { GameType, GameStatus };

export default gameFormatService;
