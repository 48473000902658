import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";

const AlertContext = React.createContext();

export function useAlert() {
  return useContext(AlertContext);
}

const AlertProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [show, setShow] = useState(false);

  const showAlertError = (message) => {
    setMessage(message);
    setType("error");
    setShow(true);
  };

  const showAlertSuccess = (message) => {
    setMessage(message);
    setType("success");
    setShow(true);
  };

  const value = {
    showAlertError,
    showAlertSuccess,
  };

  const handleClose = (event, reason) => {
    setShow(false);
  };

  return (
    <AlertContext.Provider value={value}>
      <Snackbar
        open={show}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={type ? type : "info"}>
          {message !== undefined && message.length > 0
            ? message
            : type === "error"
            ? "An unknown error accrued please report this to the administrator."
            : ""}
        </Alert>
      </Snackbar>

      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider };
