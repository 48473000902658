import { Badge, Box, Typography } from '@mui/material';

const ScoreBox = ({ score, emoji, teamTotal }) => (
  <Box display="flex" justifyContent={'center'} alignItems={'center'}>
    <Badge
      badgeContent={emoji}
      showZero={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <Typography variant="h3" role="img" aria-label="Trophy">
        {score} <Typography variant="caption">{teamTotal && `(${teamTotal})`}</Typography>
      </Typography>
    </Badge>
  </Box>
);

export default ScoreBox;
