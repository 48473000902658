import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useAuth } from '../contexts/authContext';

const Layout = (props) => {
  return <Box width={'100%'}>{props.children}</Box>;
};

export default Layout;
