import axiosInstance from '../axiosInstance';

const uploadPlayers = async (fileName, eventId) => {
  return axiosInstance.post('/player/upload', { fileName, eventId }).then((response) => {
    return response.data;
  });
};

const playerService = { uploadPlayers };

export default playerService;
