import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React from 'react';

const StagingAlert = ({ children, isStaging }) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children}

      {isStaging ? (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert variant="filled" severity="warning" onClose={handleClose}>
            <AlertTitle>Staging</AlertTitle>
            This is a staging environment
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default StagingAlert;
