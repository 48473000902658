import { useMediaQuery } from '@mui/material';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

const ColorModeContext = createContext();
export const useColorMode = () => useContext(ColorModeContext);

const ColorModeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ColorModeContext.Provider value={{ mode, toggleColorMode, setMode }}>
      {children}
    </ColorModeContext.Provider>
  );
};

export default ColorModeProvider;
