import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";

const Checkout = ({ control, name, label, color, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          control={control}
          name={name}
          defaultValue={props.value || false}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              onChange={onChange}
              checked={value}
              color={color}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
            />
          )}
        />
      }
      label={label}
      {...props}
    />
  );
};

export default Checkout;
