import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import venueService from './venueService';

export const getVenues = createAsyncThunk('venue/getVenues', async (_, thunkAPI) => {
  return await venueService
    .getAll()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const err = error.response.data;
      return thunkAPI.rejectWithValue(err.message);
    });
});

export const getVenuesByUserId = createAsyncThunk(
  'venue/getVenuesByUserId',
  async (userId, thunkAPI) => {
    return await venueService
      .getByUserId(userId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        const err = error.response.data;
        return thunkAPI.rejectWithValue(err.message);
      });
  }
);

export const venueSlice = createSlice({
  name: 'venues',
  initialState: {
    venue: null,
    venues: [],
    selectedVenue: null
  },
  reducers: {
    setSelectedVenue: (state, action) => {
      state.selectedVenue = action.payload;
    },
    setVenue: (state, action) => {
      state.venue = action.payload;
    },
    setVenues: (state, action) => {
      state.venues = action.payload;
    },
    addVenue: (state, action) => {
      state.venues.push(action.payload);
    },
    updateVenue: (state, action) => {
      const index = state.venues.findIndex((venue) => venue.id === action.payload.id);
      state.venues[index] = action.payload;
    },
    removeVenue: (state, action) => {
      state.venues = state.venues.filter((venue) => venue.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVenues.fulfilled, (state, action) => {
        state.loadingVenues = false;
        state.venues = action.payload;
      })
      .addCase(getVenues.rejected, (state, action) => {
        state.loadingVenues = false;
        state.venues = [];
      })
      .addCase(getVenues.pending, (state, action) => {
        state.loadingVenues = true;
        state.venues = [];
      })
      .addCase(getVenuesByUserId.fulfilled, (state, action) => {
        state.userVenues = action.payload;
        state.loadingVenues = false;
      })
      .addCase(getVenuesByUserId.rejected, (state, action) => {
        state.loadingVenues = false;
      })
      .addCase(getVenuesByUserId.pending, (state, action) => {
        state.loadingVenues = true;
      });
  }
});

export const { setVenue, setVenues, addVenue, updateVenue, removeVenue, setSelectedVenue } =
  venueSlice.actions;

export default venueSlice.reducer;
