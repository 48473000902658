import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { GameStatus } from '../../../constants/gameConstants';
import UndoButton from './undoButton';
import { startNewSet, startPlayoff } from '../../../features/game/gameSlice';

const GameControl = ({ game }) => {
  const dispatch = useDispatch();

  const hasSets = game.gameFormat.sets > 1 && game.status !== GameStatus.PLAYOFF;
  const currentSet = game.sets.find((set) => set.id === game.currentSet);
  const startingNewSet = useSelector((state) => state.game.startingNewSet);
  const startingPlayoff = useSelector((state) => state.game.startingPlayoff);

  const handleNextSet = () => {
    dispatch(startNewSet(game.id));
  };

  const handleStartPlayoff = () => {
    dispatch(startPlayoff(game.id));
  };

  return (
    <Stack alignItems={'center'}>
      {game.status === GameStatus.IN_PROGRESS && hasSets && (
        <>
          <Typography>{`Set`}</Typography>
          <Typography>{`${game.sets.length} / ${game.gameFormat.sets}`}</Typography>
        </>
      )}

      {game.status === GameStatus.PLAYOFF && game.playoff && (
        <Typography>{`Playoff: ${game.playoff.ends.length} / ${game.gameFormat.playoffMaxPoints}`}</Typography>
      )}

      {game.status === GameStatus.IN_PROGRESS && (
        <>
          <Typography>{`End`}</Typography>
          <Typography>{`${game.currentEnd} / ${game.gameFormat.ends === 0 ? '♾️' : game.gameFormat.ends}`}</Typography>
        </>
      )}

      <UndoButton game={game} />
    </Stack>
  );
};

export default GameControl;
