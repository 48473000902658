import { Box, MenuItem } from '@mui/material';
import Select from '../forms/fields/select';
import { useFormContext } from 'react-hook-form';

const TeamColorPicker = ({ name, defaultValue }) => {
  const { control } = useFormContext();

  return (
    <Box>
      <Select control={control} name={name} defaultValue={defaultValue}>
        <MenuItem value={'green'}>
          <Box
            sx={{
              bgcolor: 'green.main',
              width: 20,
              height: 20,
              borderRadius: '100%'
            }}
          />
        </MenuItem>
        <MenuItem value={'red'}>
          <Box
            sx={{
              bgcolor: 'red.main',
              width: 20,
              height: 20,
              borderRadius: '100%'
            }}
          />
        </MenuItem>
        <MenuItem value={'blue'}>
          <Box
            sx={{
              bgcolor: 'blue.main',
              width: 20,
              height: 20,
              borderRadius: '100%'
            }}
          />
        </MenuItem>
        <MenuItem value={'yellow'}>
          <Box
            sx={{
              bgcolor: 'yellow.main',
              width: 20,
              height: 20,
              borderRadius: '100%'
            }}
          />
        </MenuItem>
      </Select>
    </Box>
  );
};

export default TeamColorPicker;
