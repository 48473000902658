import { Box, CircularProgress, Typography } from '@mui/material';

const BSCircularProgress = ({ size, thickness, caption }) => {
  return (
    <Box display={'flex'} flexDirection="column" justifyContent={'center'} alignItems="center">
      <CircularProgress size={size} thickness={thickness} />
      <Typography variant="caption">{caption}</Typography>
    </Box>
  );
};

export default BSCircularProgress;
