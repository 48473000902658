import GameCompleteCard from './gameCompleteCard';
import GameInProgressCard from './gameInProgressCard';
import GameNotStartedCard from './gameNotStartedCard';
import gameFormatService from '../../../features/gameFormat/gameFormatService';
import GameCardBase from './gameStatusCardBase';

const GameByStatusCards = ({ game, actions = true }) => {
  switch (game.status) {
    case gameFormatService.GameStatus.NOT_STARTED:
      return (
        <GameCardBase game={game} actions={actions}>
          <GameNotStartedCard game={game} actions={actions} />
        </GameCardBase>
      );
    case gameFormatService.GameStatus.IN_PROGRESS:
      return (
        <GameCardBase game={game} actions={actions}>
          <GameInProgressCard game={game} actions={actions} />
        </GameCardBase>
      );
    case gameFormatService.GameStatus.COMPLETE:
      return (
        <GameCardBase game={game} actions={actions}>
          <GameCompleteCard game={game} actions={actions} />
        </GameCardBase>
      );
    default:
      return null;
  }
};

export default GameByStatusCards;
